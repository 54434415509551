// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYgiARaSWkhwsyeG-xnSSqwC-11i8tG1U",
  authDomain: "jarvis2-vue.firebaseapp.com",
  projectId: "jarvis2-vue",
  storageBucket: "jarvis2-vue.appspot.com",
  messagingSenderId: "926810575005",
  appId: "1:926810575005:web:f209df2e15c031d8b48474",
  measurementId: "G-KVY8D0N78B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);